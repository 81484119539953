import React from 'react';
import squareImage from '../../images/square-image/Quadrat.svg';
import '../../media-queries/menu-media-queries.css';

type SquareImageSize = 'Small' | 'Medium' | 'MediumLarge' | 'Large';

interface Props {
  size: SquareImageSize;
}

function squareSize(size: SquareImageSize): number {
  switch (size) {
    case 'Small':
      return 5;
    case 'Medium':
      return 11;
    case 'MediumLarge':
      return 17;
    case 'Large':
      return 35;
    default:
      return -1;
  }
}

export function SquareImages(props: Props): JSX.Element {
  return (
    <>
      <img
        src={squareImage}
        width={squareSize(props.size)}
        height={squareSize(props.size)}
      />
    </>
  );
}
