import React from 'react';
import BasicStructure from '../components/basic-structure/basic-structure';
import { SquareImages } from '../components/squares-images/squares-images';
import './impressum.css';
import '../media-queries/impressum-media-queries.css';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import ContentContainer from '../components/content-container/content-container';
import Footer from '../components/footer/footer';

export default function Impressum(): JSX.Element {
  return (
    <BasicStructure>
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <ContentContainer>
        <div className="impressum__container">
          <div className="impressum__title-image">
            <SquareImages size="Large" />
            <div className="impressum__title">IMPRESSUM</div>
          </div>

          <div className="impressum__box">
            <p className="impressum__zwischentitel">
              Internationale Stiftung zur Förderung von Kultur und Zivilisation
            </p>

            <div className="impressum__adresse">
              <p className="impressum__text">
                81479 München <br></br> Dr.-Carl-von-Linde-Str.9 <br></br>
                Telefon: +49 (0)89 54 04 11 8 - 0<br></br>
                Telefax: +49 (0)89 54 04 11 8 - 19
              </p>
            </div>

            <p className="impressum__link">
              E-Mail:&nbsp;
              <a
                className="impressum__email"
                href="mailto: contact@kulturstiftungmuenchen.de"
              >
                contact@kulturstiftungmuenchen.de
              </a>
            </p>

            <p className="impressum__link">
              Internet:&nbsp;
              <a
                className="impressum__linkadresse"
                href="www.internationalestiftung.de"
              >
                www.internationalestiftung.de
              </a>
            </p>

            <p className="impressum__zwischentitel">Vertretungsberechtigte:</p>

            <p className="impressum__text">
              Herr Erich Fischer (Stifter und Stiftungsrat) <br></br>Herr Jürgen
              Dorn (Vorstand) <br></br>Frau Eva Köhler (Vorstand)
            </p>

            <p className="impressum__zwischentitel">
              Verantwortlichkeit gem. § 5 TMG:
            </p>

            <p className="impressum__text">
              Persönlich verantwortlich im Sinne des § 5.TMG für die Webseite
              <br></br>
              <a
                className="impressum__linkadresse"
                href="www.internationalestiftung.de"
              >
                www.internationalestiftung.de
              </a>
              &nbsp;ist Erich Fischer (Anschrift wie oben)
            </p>

            <p className="impressum__zwischentitel">
              Konzeption und Gestaltung:
            </p>
            <p className="impressum__text">ARTWORK | Sabrina Ebmeyer</p>

            <p className="impressum__zwischentitel">Technische Umsetzung:</p>
            <a
              className="impressum__linkadresse"
              href="https://www.e-mundo.de/de"
            >
              eMundo GmbH
            </a>

            <p className="impressum__zwischentitel">
              <br />
              Registerangaben:
            </p>

            <p className="impressum__zwischenueberschrift">
              Sitz der Stiftung:&nbsp;
              <span className="impressum__text">München</span>
            </p>

            <p className="impressum__zwischenueberschrift">
              Registergericht:&nbsp;
              <span className="impressum__text">Amtsgericht München</span>
            </p>

            <p className="impressum__zwischenueberschrift">
              Umsatzsteuer-ID:&nbsp;
              <span className="impressum__text">DE255554795</span>
            </p>

            <p className="impressum__zwischenueberschrift">
              Aufsichtbehörde:&nbsp;
              <span className="impressum__text">
                Regierung von Oberbayern, Abt. für Kommunales und Soziales,
                Sachgebiet 1,<br></br> Maximilianstr. 39, 80538 München
              </span>
            </p>

            <p className="impressum__zwischenueberschrift"></p>
          </div>
          <div className="impressum__datenschutz-verlinkung">
            <a className="impressum__link" href="/datenschutz">
              Disclaimer/Datenschutz
            </a>
          </div>
        </div>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}
